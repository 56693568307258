<template lang="pug">
head-meta(title-page="Спасибо за заказ")
the-thanks
contacts-line
</template>

<script>
import TheThanks from '../components/thanks/TheThanks'
import Storage from '../assets/scripts/Storage'
import HeadMeta from '../components/_layout/HeadMeta'
import ContactsLine from '../components/_layout/TheContactsLine'

export default {
  name: 'ThanksPage',
  components: { ContactsLine, HeadMeta, TheThanks },
  beforeRouteEnter (to, from, next) {
    if (Storage.showThanks || to.query.orderId) {
      Storage.showThanks = false
      next()
    } else {
      next({ name: 'Home' })
    }
  }
}
</script>
