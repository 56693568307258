<template lang="pug">
article.thanks(v-if="user")
  .thanks__cont.cont
    h1.thanks__caption.caption Спасибо!
    p.thanks__text.text-big
      | Менеджер перезвонит для уточнения деталей в&nbsp;рабочее время:
      | по&nbsp;будням с&nbsp;8&nbsp;до&nbsp;17, СБ&nbsp;&mdash; c 9&nbsp;до&nbsp;16, ВС&nbsp;&mdash; с&nbsp;9&nbsp;до&nbsp;15
    .thanks__btn-box(v-if="user.is_auth")
      router-link.thanks__button.link-more(:to="{ name: 'Home' }") На главную
    template(v-else)
      p.thanks__text.text-big
        | Теперь у вас есть личный кабинет, где сохранён этот заказ. Проверьте почту, мы прислали пароль
        | для&nbsp;входа. Пароль можно изменить в настройках кабинета
      router-link.thanks__button.link-more(:to="{ name: 'UserOrders' }") Мои заказы
</template>

<script>
import '@/assets/styles/components/thanks.sass'
import { updateUser } from '../../assets/scripts/API'

export default {
  name: 'TheThanks',
  data () {
    return {
      user: null
    }
  },
  async mounted () {
    this.user = await updateUser()
  }
}
</script>
